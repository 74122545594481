import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
  scrollBehavior(to){
    //帮助中心明细/help/details/XX/XX不在这自动回顶部（涉及翻页问题）
    if(!( /^\/help\/details(\/\w)+/.test(to.path) )) {
      return {y: 0}
    }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'Home'
      }
    },
    {
      path: '/home', /* 首页 */
      name: 'Home',
      component: () => import(/* webpackChunkName: 'group-home' */ '@/views/home/home'),
      /*component: resolve => require(['../views/home/home.vue'], resolve)*/
      beforeEnter(to, from ,next){
        document.title = '网宿云-可靠的企业级云服务专家';
        next();
      }
    },
    {
      path: '/help', /* 帮助中心 */
      //name: 'Help',
      component: () => import(/* webpackChunkName: 'group-help' */ '@/views/help/help'),
      children: [
        {
          path: '',
          redirect: {
            name: 'HelpContents'
          }
        },
        {
          path: 'contents', /* 帮助中心列表页 */
          name: 'HelpContents',
          component: () => import(/* webpackChunkName: 'group-help-contents' */ '@/views/help/helpContents'),
          beforeEnter(to, from ,next){
            document.title = '帮助中心-网宿云';
            /*Promise.all([
              promiseRequestConfig('config/help', 'product.json', 'helpContents')
            ]).then(()=>{
              next();
            });*/
            next();
          }
        },
        {
          path: 'search', /* 帮助中心查询结果页 */
          name: 'HelpSearch',
          component: () => import(/* webpackChunkName: 'group-help-search' */ '@/views/help/helpSearch')
        },
       //   {
       //        path: 'details/:detailsId', /* 帮助中心明细页 */
       //        name: 'HelpDetailsUnNode',
       //        component: () => import(/* webpackChunkName: 'group-help-details' */ '@/views/help/helpDetails')
       // },
        {
          path: 'details/:detailsId/:nodeId', /* 帮助中心明细页 */
          name: 'HelpDetails',
          component: () => import(/* webpackChunkName: 'group-help-details' */ '@/views/help/helpDetails')
        },
        {
          path: 'details/:detailsId/:mountId/:nodeId', /* 帮助中心挂载目录 */
          name: 'HelpDetailsMounts',
          component: () => import(/* webpackChunkName: 'group-help-details' */ '@/views/help/helpDetails')
        }
      ]
    },
    {
      path: '/product/:dataSource', /* 产品页 */
      name: 'Product',
      component: () => import(/* webpackChunkName: 'group-product' */ '@/views/product/product')
    },
    {
      path: '/aws', /* aws合作wrap*/
      name: 'Aws',
      component: () => import(/* webpackChunkName: 'group-aws' */ '@/views/aws/index'),
      children: [
        {
          path: '',
          redirect: {
            name: 'AwsHome'
          }
        },
        {
          path: 'home', /* aws合作首頁 */
          name: 'AwsHome',
          component: () => import(/* webpackChunkName: 'group-aws-home' */ '@/views/aws/home')
        },
        {
          path: 'service', /* aws服务 */
          name: 'AwsService',
          component: () => import(/* webpackChunkName: 'group-aws-service' */ '@/views/aws/service')
        },
        {
          path: 'customer', /* aws客户案例 */
          name: 'AwsCustomer',
          component: () => import(/* webpackChunkName: 'group-aws-customer' */ '@/views/aws/customer')
        },
        {
          path: 'contactUs', /* aws联系我们 */
          name: 'AwsContactUs',
          component: () => import(/* webpackChunkName: 'group-aws-contactUs' */ '@/views/aws/contactUs')
        },
      ]
    },
    {
      path: '/productPreview/:dataSource', /* 产品预览页 */
      name: 'ProductPreview',
      component: () => import(/* webpackChunkName: 'group-productPreview' */ '@/views/productPreview/productPreview')
    },
    {
      path: '/solution/:dataSource', /* 解决方案页 */
      name: 'Solution',
      component: () => import(/* webpackChunkName: 'group-solution' */ '@/views/solution/solution')
    },
    {
      path: '/feedback',  /* 建议反馈 */
      name: 'Feedback',
      component: () => import(/* webpackChunkName: 'group-feedback' */ '@/views/feedback/feedback'),
      beforeEnter(to, from ,next){
        document.title = '建议反馈-网宿云';
        next();
      }
    },
    {
      path: '/news',    /* 相关资讯页 */
      name: 'News',
      component: () => import(/* webpackChunkName: 'group-new' */ '@/views/about/news')
    },
    {
      path: '/newsDetail/:id', /* 资讯明细页 */
      name: 'NewsDetail',
      component: () => import(/* webpackChunkName: 'group-newsDetail' */ '@/views/about/newsDetail')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import(/* webpackChunkName: 'group-about' */ '@/views/about/about'),
      beforeEnter(to, from ,next){
        document.title = '了解网宿-网宿云';
        next();
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import(/* webpackChunkName: 'group-contact' */ '@/views/about/contact'),
      beforeEnter(to, from ,next){
        document.title = '联系我们-网宿云';
        next();
      }
    },
    {
      path: '/privacyTerms',
      name: 'PrivacyTerms',
      component: () => import(/* webpackChunkName: 'group-privacyTerms' */ '@/views/privacyTerms/privacyTerms'),
      beforeEnter(to, from ,next){
        document.title = '隐私条款';
        next();
      }
    },
    {
      path: '/authentication', /* 资讯明细页 */
      name: 'Authentication',
      component: () => import(/* webpackChunkName: 'group-authentication' */ '@/views/authentication/authentication')
    },
    {
      path: '/aiExperience', /* 资讯明细页 */
      name: 'AIExperience',
      component: () => import(/* webpackChunkName: 'group-aiExperience' */ '@/views/aiExperience/aiExperience')
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: 'group-err404' */ '@/views/errorPage/error404'),
      beforeEnter(to, from ,next){
        document.title = '404';
        next();
      }
    }
  ]
})
