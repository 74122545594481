<template>
  <div id="browser_version">
    <div class="browser_version_container">
      <img src="../../../static/regular/img/common/browserVersion.png" alt="">
      <div class="browser_version_content">
        <h1>Sorry，Your browser version is too low.</h1>
        <p>Please upgrade your browser or use the following browsers.</p>
        <p>Chrome、Firefox、IE（IE 10 or higher）、Safari、Opera</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "BrowserVersion",
    created(){
      document.body.style.overflow = 'hidden';//隐藏滚动条使其不可滚动
    }
  }
</script>
<style lang="scss" scoped>
  @import './style/browserVersion.scss';
</style>
