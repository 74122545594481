import Vue from 'vue'
import imgDirective from './img'
const directives = {
  'img': imgDirective
}
export default () => {
  for(let key in directives){
    Vue.directive(key, directives[key]);
  }
}
