import homeService from './home';
import helpService from './help';
import taskProjectService from './task-project';
import taskListService from './task-project';

export default {
    homeService,
    helpService,
    taskProjectService,
    taskListService,
}