import store from '../vuex'
//请求图片
const requestImg = (el) => {
  return new Promise((resolve) => {
    const url = el.dataset ? el.dataset.src : el.getAttribute('data-src');
    let img = new Image();

    img.onload = function(){
      el.nodeName.toUpperCase() === 'IMG' ? (el.src = url) : (el.style.backgroundImage = 'url("'+ url +'")');
      resolve();
    }
    img.onerror = function(){
      resolve(); //请求失败也过，否则会造成堵塞
    }
    img.src = url;
  })
}
//判断是否在首屏
const inViewPort = (el) => {
  const rect = el.getBoundingClientRect()
  return rect.top > 0
    && rect.bottom < window.innerHeight
    && rect.left > 0
    && rect.right < window.innerWidth;
}
export default {
  bind(el, binding){
    //isAdvance：是否首屏加载 type：img/background-image
    const {isAdvance} = binding.value || {};
    //判断是否优先加载
    if(isAdvance){
      store.commit('addAdvanceImgs', requestImg(el));
    }
  },
  inserted(el, binding){
    const {isAdvance} = binding.value || {};
    if(isAdvance) return;
    if(inViewPort(el)){
      store.commit('addAdvanceImgs', requestImg(el));
      //advanceImgPromise.push(requestImg(el));
    }else{
      Vue.nextTick(() => {
        Promise.all(store.state.advanceImgPromise).then(() => {
          store.commit('clearAdvanceImgs');
          store.commit('addDeferImgs', requestImg(el));
        })
      })
    }
  }
}
