import api from '../request';
const taskProjectService = {
    // 查询个人的项目
    queryPersonalProject(url, params) {
        // return api.post(url, params);
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([
                    {
                        name: '私有项目',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'blue',
                    }
                ]);
            }, 150);
        })
    },
    // 查询参与的项目
    queryPartInProject(url, params) {
        // return api.post(url, params);
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([
                    {
                        name: '汪汪大队',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'pale_blue',
                        count: 12,
                    },
                    {
                        name: '云市场推广分队',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'dark_blue',
                        count: 12,
                    },
                    {
                        name: '云通信市场2组',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'blue',
                        count: 12,
                    },
                    {
                        name: '云通信产品运营',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'yellow',
                        count: 12,
                    },
                    {
                        name: '云计算团建',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'blue',
                        count: 12,
                    },
                ]);
            }, 150);
        })
    },
    // 查询回收站项目
    queryRecycleProject() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([
                    {
                        name: '回收站',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'dark_blue',
                    }
                ]);
            }, 150);
        })
    },
    // 查询已归档项目
    queryPigeonholeProject() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([
                    {
                        name: '已归档',
                        desc: '进行中 31（我负责的 31 提到我的 2）',
                        type: 'yellow',
                    }
                ]);
            }, 150);
        })
    },
}
export default taskProjectService;