import Vue from 'vue'
//获取元素到body顶部的距离
let getOffset = function(dom){
  let o = {left:0 ,top: 0};
  while(dom !== document.body){
    o.top  += dom.offsetTop;
    o.left += dom.offsetLeft;
    dom = dom.offsetParent;
  }
  return o;
};
//滚动效果
// 参数：1、滚动到的DOM节点 2、滚动速度(默认15)
let scrollTo = (function(){
  let timer = null;
  return function(ele, speed=15){
    //从当前位置滚动到指定位置
    let scrollTopFrom = document.body.scrollTop || document.documentElement.scrollTop,
        scrollTopTo = getOffset(ele).top;
    let d_value = (scrollTopTo - scrollTopFrom)/speed;//每次滚动的偏移值
    timer = setInterval(function(){
      scrollTopFrom += d_value;
      scrollTopFrom = Math[d_value>0 ? 'min': 'max'](scrollTopFrom,scrollTopTo);
      //document.documentElement.scrollTop = scrollTopFrom;
      window.scrollTo(0, scrollTopFrom);
      (scrollTopFrom === scrollTopTo) && clearInterval(timer);
    },1000/60);
  }
})();

//获取元素属性值
let getComputed = (function(){
  return function(dom, attr){
    if( dom.currentStyle){
      return dom.currentStyle[attr];
    }else{
      return getComputedStyle(dom)[attr];
    }
  }
}());
//回到浏览器顶部
let refreshTopPosition = (function(){
  return function(){
    //document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  }
}());
//校验IE版本
let validBrowser = (function(){
  return function(){
    //取得浏览器的userAgent字符串
    var userAgent = navigator.userAgent;
    //判断是否IE浏览器
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
    if (isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion < 10) {
        return true;
      }else{
        return false;
      }
    } else {
      return false;
    }
  }
}());
//promise请求json配置
let promiseRequestConfig = (function(){
  return function (path, fileName,cacheName, isCustom) { //例  path: config/common   fileName: nav_data.json  cacheName: nav_data  isCustom:true（是否自定义前缀路径)
    return new Promise((resolve, reject)=>{
      if(Vue.prototype.cache[cacheName]){
        //如果已有数据，不需要再去请求
        resolve(Vue.prototype.cache[cacheName]);
        return;
      }
      Vue.prototype.$axios.get(isCustom ? `${path}/${fileName}`:`${Vue.prototype.configurablePath}/${path}/${fileName}?t=${Date.now()}`)
        .then((res)=>{
          Vue.prototype.cache[cacheName] = res.data;
          resolve(Vue.prototype.cache[cacheName]);
        }).catch(()=>{
          reject();
        })
    })
  }
}());
//封装请求导航栏的nav配置（目前就产品页可配置）
let promiseRequestNavConfig = (function(){
  let navPromiseCache = {};//导航promise请求缓存（当header和home同时去请求时，只请求一次）
  return function(){
    let navPromise = navPromiseCache['nav'];
    !navPromise && (
      navPromise = new Promise((resolve, reject) => {
        Promise.all([
          promiseRequestConfig('config/common', 'nav_product.json', 'nav_product')
          /*promiseRequestConfig('config/common', 'nav_managedCloud.json', 'nav_managedCloud'),
          promiseRequestConfig('config/common', 'nav_solution.json', 'nav_solution'),
          promiseRequestConfig('config/common', 'nav_steady.json', 'nav_steady')*/
        ]).then(()=>{
          //合并nav_data数据
          const manageCloudData = require('../../../static/regular/config/common/nav_managedCloud'),
            EdgeComputingData = require('../../../static/regular/config/common/nav_edgeComputing'),
            solutionData = require('../../../static/regular/config/common/nav_solution'),
            steadyData = require('../../../static/regular/config/common/nav_steady');
          /*Vue.prototype.cache.nav_data = [
            ...[Vue.prototype.cache.nav_product],
            ...[Vue.prototype.cache.nav_managedCloud],
            ...[Vue.prototype.cache.nav_solution],
            ...Vue.prototype.cache.nav_steady
          ];*/
          Vue.prototype.cache.nav_data = [
            ...[Vue.prototype.cache.nav_product],
            ...[manageCloudData],
            ...[EdgeComputingData],
            ...[solutionData],
            ...steadyData
          ];
          resolve(Vue.prototype.cache.nav_data);
        }).catch(()=>{
          reject();
        });
      }),
        navPromiseCache['nav'] = navPromise
    );
    return navPromise;
  }
}());
//Cookie get set
let Cookie = {
  SET : function(json, time, path) {
    let date = new Date(Date.now() + (time ? time : 0) * 24 * 60 * 60 * 1000);
    for (let key in json){
      document.cookie = (key + '=' + json[key] + ';')
        + (time ? ('expires=' + date.toUTCString() + ';') : '')
        + (path ? ('path=' + path + ';'): 'path=/');
    }
  },
  GET : function(key){
    return document.cookie.match(new RegExp("(^|\\s)" + key + "=([^;]*)(;|$)"))[2];
  }
}

//封装绑定滚动事件
let mouseWheel = (function(){
  return function(ele, eFn){
    function fn(e){
      e = e || window.event;
      //让滚动方向统一 （向上滚动>0、向下滚动<0）
      let direction = e.wheelDelta / 120 || -e.detail / 3;
      eFn.call(ele, e, direction);
    }
    //判断是否是火狐浏览器
    let type = document.onmousewheel === null? 'mousewheel' : 'DOMMouseScroll';
    //判断是否是IE8
    window.addEventListener ? ele.addEventListener(type, fn)
      : ele.attachEvent('on' + type, fn);
  }
}());

//比较对象是否一致
let compareObj = (function(){
  let isObj = (object) => {
    return object && typeof(object) == 'object';
  }
  //判断长度
  let getLength = (object) => {
    var count = 0;
    for(var i in object) count++;
    return count;
  }
  let compareObjContent = (o1, o2) => {
    for(let key in o1){
      if(!o2.hasOwnProperty(key))
        return false;
      if(typeof o1[key] === 'object'){
        if(!compareObjContent(o1[key], o2[key]))
          return false;
      }else{
        if(o1[key] !== o2[key])
          return false;
      }
    }
    return true;
  }
  return function (o1, o2) {
    if(!isObj(o1) || !isObj(o2)) return false;
    if(getLength(o1) != getLength(o2)) return false;
    return compareObjContent(o1,o2) && compareObjContent(o2,o1);
  }
}());

/**
 * 判断浏览器是否有滚动条
 * @returns {boolean}
 */
const windowScroll = () => {
    return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
}

//工具方法
/*let commonUtils = {
  scrollTo, //滚动效果
  getComputed //获取元素属性值
}*/

export {
  getOffset,
  scrollTo,
  getComputed,
  refreshTopPosition,
  validBrowser,
  promiseRequestConfig,
  promiseRequestNavConfig,
  Cookie,
  mouseWheel,
  compareObj,
  windowScroll,
}
