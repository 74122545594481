import axios from './axios'

export default {
    // get请求
    get(url, params, headers) {
        let options = {};
        if (params) {
            options.params = params;
        }
        if (headers) {
            options.headers = headers;
        }
        return axios.get(url, options);
    },
    // post请求
    post(url, params, headers, data) {
        let options = {};
        if (params) {
            options.params = params;
        }
        if (headers) {
            options.headers = headers;
        }
        return axios.post(url, data, options);
    },
    // put请求
    put(url, params, headers) {
        let options = {};
        if (headers) {
            options.headers = headers;
        }
        return axios.put(url, params, options);
    },
    // delete请求
    delete(url, params, headers) {
        let options = {};
        if (params) {
            options.params = params;
        }
        if (headers) {
            options.headers = headers;
        }
        return axios.delete(url, options);
    }
}