import services from './services';

const install = Vue => {
    Object.assign(Vue.prototype, {
        '$api': services
    })
}

export default {
    install
}