import axios from 'axios'

// 开发环境代理、build环境用nginx
const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL : '';
// 创建axios 实例
const service = axios.create({
    baseURL, // api的base_url
    timeout: 10000 // 请求超时时间
})

// request 拦截器
service.interceptors.request.use(
    config => {
        // 这里可以自定义一些config 配置
        return config;
    },
    error => {
        //  这里处理一些请求出错的情况
        console.log(error);
        Promise.reject(error);
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const { data } = response;
        // 这里处理一些response 正常放回时的逻辑
        return data;
    },
    error => {
        // 这里处理一些response 出错时的逻辑
        let { status, statusText, data } = error.response,
            info = !error.response ? {code: 5000, msg: 'Network Error'} : {code: status, data: data, msg: statusText};
        return Promise.reject(info)
    }
)

export default service