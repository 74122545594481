import Vue from 'vue';
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
  advanceImgPromise: [], //优先加载的图片
  deferImgPromise: [] //排在优先加载后的图片
}
const mutations = {
  clearAdvanceImgs(state){
    state.advanceImgPromise.length = 0;
  },
  clearDeferImgs(state){
    state.deferImgPromise.length = 0;
  },
  addAdvanceImgs(state, item){
    state.advanceImgPromise.push(item);
  },
  addDeferImgs(state, item){
    state.deferImgPromise.push(item);
  }
}
export default new Vuex.Store({
  state,
  mutations
})
