// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill' /* 引入babel-polyfill兼容IE */
import axios from 'axios';
import App from './App.vue'
import directivesBind from './directives'/* 引入指令 */
directivesBind();
import store from './vuex' /* 引入vuex */
import BrowserVersion from './components/browserVersion/browserVersion' /* 版本不兼容页面 */
import {refreshTopPosition, validBrowser, Cookie} from  './assets/utils/commonUtils' /* 引入回到浏览器顶部 */
Vue.prototype.refreshTopPosition = refreshTopPosition;
import router from './router'
import Api from './api';
import Vue from 'vue';

import SalusUI from '@salus/vue-components';
import '@salus/vue-components/style/salus.css'
import '../src/assets/style/commom.css'
Vue.use(SalusUI);

import { requestPath } from './environment'; // 运维配置自定义环境

/*axios.interceptors.request.use( /!* 配置axios请求拦截设置cookie *!/
  config => {
        if(config.url.indexOf(Vue.prototype.requestPath)!==-1){
            Cookie.SET({
                service_language: 'zh_cn'
            }, 1, '/');
        }
        return config;
    }
)

axios.interceptors.response.use(
    response => {
        if(response.config.url.indexOf(Vue.prototype.requestPath)!==-1){
            Cookie.SET({
                service_language: 'zh_cn'
            }, -1, '/');
        }
        return response;
    }
)*/
axios.defaults.withCredentials=true; /* 设置请求携带cookie */
Vue.prototype.$axios = axios; /* 绑定axios到vue原型 */

Vue.prototype.$qs = Qs; /* 绑定qs到vue原型 */
Vue.prototype.$ = $ || jQuery; /* 绑定jQuery到vue原型 */

Vue.prototype.requestPath = '/base-portal' || requestPath;

// Vue.prototype.configurablePath = process.env.NODE_ENV === 'development' ? './static/configurable' : '/configurable';
Vue.prototype.configurablePath = '/configurable';

Vue.prototype.regularPath = './static/regular' /* 常规文件访问路径 */

Vue.prototype.preViewPath = '/draft' /* 临时测试图片预览 */

Vue.prototype.cache = {}; /* 页面加载缓存数据 */

Vue.config.productionTip = false

// 安装api
Vue.use(Api);
/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App, BrowserVersion },
    /*template: '<App/>',*/
    render: (t)=> {
        //检测ie版本
        if(validBrowser())
            return t(BrowserVersion);
        return t(App);
    }
})
